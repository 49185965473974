import { useState } from "react";
import { BusinessName } from "./business-name";
import { BusinessPrimaryInfo } from "./business-primary-info";
import { BusinessSecondaryInfo } from "./business-secondary-info";
// import { VerifyDetail } from "./verify-detail";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "assets/svg/tradetracker-logo.svg";
import { BiChevronLeft } from "react-icons/bi";
import { BusinessUpload } from "./business-upload";
import { BusinessUser } from "./business-user";
import { BusinessUploadBanner } from "./business-upload-banner";

export const BusinessAuth = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [steps] = useState([
        { component: <BusinessName nextPage={nextPage} /> },
        // { component: <BusinessUser nextPage={nextPage} /> },
        { component: <BusinessPrimaryInfo nextPage={nextPage} /> },
        { component: <BusinessSecondaryInfo nextPage={nextPage} /> },
        { component: <BusinessUploadBanner nextPage={nextPage} /> },
        { component: <BusinessUpload nextPage={nextPage} /> },
    ]);

    function nextPage(num: number) {
        setActiveStep(num);
    }

    function prevPage() {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    }

    return (
        <>
            <div className="flex flex-col bg-[#F0F0F0] justify-center items-center gap-8 auth min-h-[100vh] py-10">
                <Link
                    to="/"
                    className="font-extrabold text-2xl text-gray-500 mb-5"
                >
                    <Logo />
                </Link>
                <div className="auth-form-wrapper bg-white pt-10 pb-6 px-8 rounded-2xl max-w-[500px] w-[90%] mx-auto">
                    <div className="flex items-center justify-between mb-8 text-grey-500">
                        <div
                            onClick={prevPage}
                            className="flex items-center cursor-pointer"
                        >
                            <BiChevronLeft className="text-2xl font-light mb-[2px]" />{" "}
                            <p>Back</p>{" "}
                        </div>
                        <div className="">
                            <p>
                                Step {activeStep + 1} of {steps.length}
                            </p>
                        </div>
                    </div>
                    {steps[activeStep].component}
                </div>
            </div>
        </>
    );
};
