/* eslint-disable react-hooks/exhaustive-deps */

import { useFormik } from "formik";
import { usePageNotificationProvider } from "providers/pageNotificationProvider";
import { AuthHeaderText } from "components/custom";
import { PrimaryButton, PrimaryDropzone } from "components/index";
import { IBusinessUserInterface } from "./business-Interface";
import { reactLocalStorage } from "reactjs-localstorage";
import { useEffect, useState } from "react";
import { usePostBusinessUploadMutation } from "store/business";
import { ReactComponent as WhiteImgIcon } from "assets/svg/img-placeholder-white.svg";
import { resolveApiError } from "utilities/errorHandling";
import { Spinner } from "@chakra-ui/react";

interface Props {
    nextPage: (num: number) => void;
}

export const BusinessUploadBanner = ({ nextPage }: Props) => {
    const [files, setFiles] = useState<any>([]);
    const [submitting, setSubmitting] = useState(false);
    const [values, setValues] = useState({});
    const [preview, setPreview] = useState<string>("");
    const previousValues: IBusinessUserInterface = JSON.parse(
        reactLocalStorage.get("@business_info_payload") || "{}"
    );
    const [postBusinessUpload, { upLoading }] = usePostBusinessUploadMutation();
    const { initNotification } = usePageNotificationProvider();
    const { handleSubmit } = useFormik({
        initialValues: {
            businessBanner: "",
        },
        validationSchema: null,
        onSubmit: (e) => initRequest(e),
    });

    const initRequest = async (payload?: any) => {
        setSubmitting(true);
        const form = new FormData();
        form.append("file", files[0].data);
        form.append("type", "logo");

        // console.log(values);
        // console.log(files);

        postBusinessUpload(form)
            .unwrap()
            .then((res: any) => {
                if (res.error)
                    initNotification({
                        message: res.message,
                        scheme: "error",
                    });
                initNotification({
                    message: res.message,
                    scheme: "success",
                });
                const updatedValues: IBusinessUserInterface = {
                    ...previousValues,
                    business: {
                        ...previousValues?.business,
                        businessBanner: res.data?.imageUrl,
                    },
                };

                reactLocalStorage.set(
                    "@business_info_payload",
                    JSON.stringify(updatedValues)
                );
                setSubmitting(false);

                nextPage && nextPage(4);
            })
            .catch((error: any) => {
                console.log(error);
                initNotification({
                    message: resolveApiError(error),
                    scheme: "error",
                });
            });
    };

    useEffect(() => {
        if (previousValues) {
            setValues((prev) => {
                return {
                    ...prev,
                    businessBanner: previousValues?.business?.businessBanner,
                };
            });
        }
    }, []);

    useEffect(() => {
        const image = files[0]?.data;
        if (image) setPreview(URL.createObjectURL(image));
    }, [files]);

    return (
        <>
            <AuthHeaderText
                title="Add your business banner"
                // subTitle="Add Photos of your business."
                headingClassName="text-black text-3xl font-medium mb-5"
            />
            <form onSubmit={handleSubmit} className="">
                {!preview ? (
                    <PrimaryDropzone type="logo" setFile={setFiles} />
                ) : (
                    <div className="">
                        <img
                            src={preview}
                            className="w-full h-80 object-contain"
                            alt="preview"
                        />
                        <br />
                        <PrimaryButton
                            type="reset"
                            onClick={() => {
                                setPreview("");
                                setFiles([]);
                            }}
                            className="primary-btn py-6 px-10"
                        >
                            <>Clear</>
                        </PrimaryButton>
                    </div>
                )}

                <div className="flex items-center gap-4 mt-10">
                    <PrimaryButton
                        type="submit"
                        className="primary-btn flex gap-4 py-6 px-10"
                        isLoading={upLoading}
                    >
                        <WhiteImgIcon className="w-4 h-4 text-base" />
                        <p>Submit</p>
                    </PrimaryButton>
                </div>
            </form>
            {upLoading && <LoadingPopUp />}
            {submitting && <LoadingPopUp />}
        </>
    );
};

const LoadingPopUp = () => {
    return (
        <div className="fixed bg-white/80 inset-0 z-10 flex flex-col items-center">
            <div className="p-6 flex items-center flex-col justify-center gap-8 h-full max-h-96 w-full my-auto">
                <Spinner color={"#001c89"} />
            </div>
        </div>
    );
};
